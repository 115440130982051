@import url(googleapis.css);
@import url(slick.css);
@import url(jquery-ui.min.css);
@import url(jquery-ui.structure.min.css);
@import url(jquery.formstyler.css);
@import url(jquery.fancybox.min.css);
@import url(fontello.css);
/* ------------------------------- Global  ------------------------------- */
html, body { height: 100%; overflow-x: hidden; }

html { background: #eaeaea; }

body { min-width: 320px; font: 18px/1.38 'Open Sans', sans-serif; font-display: swap; color: #000; }

body, dl, dd, blockquote, fieldset, legend { margin: 0; padding: 0; }

fieldset { border: none; }

table { border: none; border-collapse: collapse; }

th, td { vertical-align: top; }

th { text-align: left; font-weight: normal; }

address { font-style: normal; }

p, ul, ol { margin: 0 0 1.1em 0; }

ul { list-style-type: square; }

img { max-width: 100%; vertical-align: top; }

iframe[src="//t.insigit.com/nsapi/static/partners.html"] { display: none; }

*, *::before, *::after { -webkit-box-sizing: border-box; box-sizing: border-box; }

.clearfix::after { content: ""; display: block; clear: both; font-size: 0; line-height: 0; }

#wrapper { background: #fff; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; max-width: 1950px; min-height: 100%; margin: 0 auto; /*overflow-x: hidden;*/ position: relative; -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); }

.in { width: 100%; max-width: 1236px; margin: 0 auto; padding: 0 20px; position: relative; }

/* ------------------------------- Txt  ------------------------------- */
h1, h2, h3, h4, h5, h6 { margin: 0 0 0.5em 0; line-height: 1.3; }

.title1 { font-weight: 700; font-size: 48px; }

.title2 { margin-bottom: 1.75em; font-weight: 400; font-size: 24px; }

.title3 { font-weight: 600; font-size: 18px; }

.title4 { margin-bottom: 0.8em; font-weight: 700; font-size: 18px; }

.title5 { margin-bottom: 1.65em; font-family: 'Play', sans-serif; font-weight: 700; font-size: 48px; line-height: 1.15; color: #e53027; }

.title6 { margin-bottom: 1.65em; font-family: 'Play', sans-serif; font-weight: 700; font-size: 36px; line-height: 1.15; }

.title7 { margin-bottom: 1.65em; font-family: 'Play', sans-serif; font-weight: 700; font-size: 24px; line-height: 1.15; }

.title8 { font-family: 'Play', sans-serif; font-weight: 700; font-size: 18px; line-height: 1.15; }

.list1 { padding-left: 2.38em; list-style-type: none; }

.list1 li { margin-bottom: 0.55em; }

.list1 li::before { content: "\25FC"; display: inline-block; width: 2.2em; margin-left: -2.2em; position: relative; top: -0.2em; font-size: 0.7em; }

.tbl_hide, .tbl_hide th, .tbl_hide td { border: none; }

.tbl_border, .tbl_border th, .tbl_border td { border: 1px solid #333; }

.tbl_overflow { position: relative; overflow: auto; }

.tbl_overflow table { min-width: 640px; }

/* ------------------------------- Links  ------------------------------- */
:focus { outline: none; }

a img { border: none; }

a { color: #e53027; transition: all 0.3s ease; }

a:hover { text-decoration: none; }

a[href^="tel"] { color: inherit; white-space: nowrap; text-decoration: none; }

/* ------------------------------- Forms  ------------------------------- */
.input { background: #fff; width: 100%; margin: 0; padding: 12px 20px; border: 1px solid #e53027; font-family: inherit; font-size: inherit; line-height: 1.38; color: #302f2f; -webkit-appearance: none; }

textarea { height: 100px; overflow: auto; resize: none; vertical-align: top; }

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder { color: inherit; opacity: 1; }

input::-moz-placeholder, textarea::-moz-placeholder { color: inherit; opacity: 1; }

input:-moz-placeholder, textarea:-moz-placeholder { color: inherit; opacity: 1; }

input:-ms-input-placeholder, textarea:-ms-input-placeholder { color: inherit; opacity: 1; }

input[type="submit"], input[type="button"], button { cursor: pointer; }

.switch { display: inline-block; cursor: pointer; }

.switch input { display: none; }

.switch span { color: #868686; }

.switch input:checked ~ span { color: #302f2f; }

.btn { background-color: #e53027; display: inline-block; padding: 1em; border: none; font-family: inherit; font-size: inherit; line-height: 1.25; color: #fff; text-align: center; text-decoration: none; cursor: pointer; -webkit-appearance: none; transition: all 0.3s ease; }

.btn:hover { background-color: #505050; }

.btn:focus { background-color: #302f2f; }

.btn.v1 { background-color: transparent; padding-top: 13px; padding-bottom: 13px; border: 1px solid; color: #e53027; }

.btn.v1:hover, .btn.v1:focus { background-color: #e53027; color: #fff; }

.btn-prld { display: inline-flex; justify-content: center; align-items: center; min-height: 58px; }

.btn-prld span { background: #fff; width: 10px; height: 10px; margin: 0.25em 0.56em; }

.btn-prld span:nth-child(1) { animation: btn_preloader 0.6s infinite alternate; }

.btn-prld span:nth-child(2) { animation: btn_preloader 0.6s infinite alternate; animation-delay: 100ms; }

.btn-prld span:nth-child(3) { animation: btn_preloader 0.6s infinite alternate; animation-delay: 200ms; }

@keyframes btn_preloader { 0% { transform: translateY(0.55em); }
  100% { transform: translateY(-0.55em); } }

/* ------------------------------- Header  ------------------------------- */
#header { padding-bottom: 60px; z-index: 200; }

.hdr_row { background: #e5e5e5; }

.hdr_row .in { display: flex; justify-content: center; align-items: center; height: 60px; }

.logo { align-self: flex-start; margin-right: auto; position: relative; z-index: 2; }

.h_menu { display: flex; margin: 0 0 0 20px; padding: 0; list-style: none; position: relative; z-index: 2; }

.h_menu > li { margin-right: 16px; position: relative; }

.h_menu a { display: block; min-width: 102px; padding: 18px 15px; color: inherit; text-decoration: none; }

.h_menu > li > a { text-align: center; }

.h_menu > li:hover > a, .h_menu > li.active > a { background: #505050; color: #fff; }

.hm_ddown { background: #505050; display: none; margin: 0; padding: 0; list-style: none; position: absolute; top: 100%; left: 0; white-space: nowrap; }

.hm_ddown > li { position: relative; }

.hm_ddown li a { color: #fff; }

.hm_ddown a.v1 { padding-right: 40px; position: relative; }

.hm_ddown a.v1::after { content: "\e861"; position: absolute; top: 50%; right: 15px; transform: translateY(-50%); font-family: "fontello"; font-size: 10px; line-height: 1; }

.hm_ddown li:hover > a { background: #e53027; }

.hm_ddown1 { background: #868686; display: none; margin: 0; padding: 0; list-style: none; position: absolute; top: 0; left: 100%; }

.h_menu li:hover > ul { display: block; }

.h_lang { display: flex; position: absolute; bottom: -60px; right: 20px; }

.h_lang a { width: 34px; height: 34px; font-size: 14px; line-height: 34px; color: #fff; text-decoration: none; text-align: center; background-color: #c0c0c0; }

.h_lang a.active { background-color: #505050; }

.h_phone { margin-right: 20px; }

.search_ctrl { margin-left: auto; font-size: 22px; line-height: 34px; color: #ed3237; text-align: center; cursor: pointer; }

.search_form form { position: relative; }

.search_form input[type="text"] { background: none; width: 100%; padding: 0.92em 62px 0.92em 20px; border: 2px solid #c0c0c0; font-size: inherit; line-height: 1.3; }

.search_form button { background: none; width: 62px; height: calc(100% - 4px); padding: 0; border: none; position: absolute; top: 2px; right: 2px; font-size: 22px; color: #e53027; }

.search_form .hint { margin-top: 2.5em; }

#search_popup { background: none; display: none; width: 100%; max-width: 954px; padding: 0; }

#search_popup input[type="text"] { background: none; border-color: #fff; color: #fff; }

#search_popup button { color: #fff; }

#search_popup button:hover { color: #ed3237; }

.m_ctrl { background: #e53027; display: none; width: 60px; height: 60px; margin-left: 20px; font-size: 28px; line-height: 60px; color: #fff; text-align: center; cursor: pointer; }

.m_ctrl .icon-close, .m_ctrl.active .icon-burger { display: none; }

.m_ctrl.active .icon-close { display: block; }

.mob_menu_wr { background: #505050; display: none; width: calc(100% - 160px); max-width: 360px; max-height: calc(100vh - 60px); position: absolute; top: 100%; right: 20px; overflow: auto; color: #fff; }

.mm_lang { background: #868686; display: flex; padding: 0 20px; line-height: 2.5em; }

.mm_lang a { margin-right: 10px; color: inherit; text-decoration: none; }

.mm_lang a.active { color: #000; }

.mob_menu { margin: 0; padding: 0; list-style: none; }

.mob_menu a { display: block; padding: 0.572em 20px; color: inherit; text-decoration: none; }

.mob_menu li.active > a, .mob_menu a:hover { background: #e53027; }

.mm_ddown_item::after { content: "\e860"; margin-left: 1em; font-family: "fontello"; font-size: 0.8em; }

.mm_ddown_item.open::after { content: "\e85f"; }

.mm_ddown { background: #868686; display: none; margin: 0; padding: 0; list-style: none; }

#header.v1 .hdr_row .in { position: static; }

#header.v1 .logo { margin-top: -60px; }

#header.v1 .h_lang { width: 100%; max-width: 1440px; -webkit-box-pack: end; -ms-flex-pack: end; justify-content: flex-end; bottom: auto; top: 0; right: 50%; -webkit-transform: translateX(50%); -ms-transform: translateX(50%); transform: translateX(50%); }

/* ------------------------------- Main  ------------------------------- */
#main { -webkit-box-flex: 1; -ms-flex-positive: 1; flex-grow: 1; -ms-flex-negative: 0; flex-shrink: 0; padding-top: 115px; position: relative; }

.breadcrumbs { display: flex; align-items: baseline; margin: 0 0 3.5em 0; padding: 0; list-style: none; font-size: 14px; color: #505050; }

.breadcrumbs li { flex-shrink: 0; margin-bottom: 0.3em; }

.breadcrumbs li::after { content: "\e869"; margin: 0 1em; font-family: "fontello"; font-size: 1.38em; }

.breadcrumbs li:last-child { flex-shrink: 1; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }

.breadcrumbs li:last-child::after { display: none; }

.breadcrumbs a { text-decoration: none; color: inherit; }

.slick-arrow { background: #c0c0c0; width: 50px; min-width: auto; height: 50px; margin-top: -25px; padding: 0; border: none; position: absolute; top: 50%; z-index: 2; font-size: 0; line-height: 0; color: #302f2f; }

.slick-prev { left: 0; }

.slick-next { right: 0; }

.slick-prev::before, .slick-next::before { font-family: "fontello"; font-weight: normal; font-size: 20px; line-height: 1; }

.slick-prev::before { content: "\e85d"; }

.slick-next::before { content: "\e85e"; }

.slick-arrow:hover, .slick-arrow.slick-disabled { background: #302f2f; color: #c0c0c0; }

.slick-dots { margin: 0; padding: 15px 0; list-style: none; font-size: 0; line-height: 0; text-align: center; }

.slick-dots li { display: inline-block; vertical-align: middle; margin: 5px 10px; }

.slick-dots button { background: none; width: 10px; min-width: auto; height: 10px; padding: 0; font-size: 0; line-height: 0; }

.slick-dots button::before { content: ""; background: #fff; display: inline-block; width: 6px; height: 6px; border-radius: 50%; opacity: 0.9; }

.slick-dots li.slick-active button::before { background: #197317; width: 10px; height: 10px; }

.tabs .ui-helper-clearfix::before, .tabs .ui-helper-clearfix::after { display: none; }

.ui-tabs .ui-tabs-nav { margin: 0; padding: 0; list-style: none; line-height: inherit; }

.ui-tabs .ui-tabs-nav li { margin: 0; padding: 0; float: none; white-space: normal; }

.ui-tabs .ui-tabs-nav li:last-child { margin-right: 0; }

.ui-tabs .ui-tabs-nav li.ui-tabs-active { margin-bottom: 0; padding-bottom: 0; }

.ui-tabs .ui-tabs-nav .ui-tabs-anchor { padding: 0; float: none; color: inherit; text-decoration: none; }

.ui-tabs .ui-tabs-panel { padding: 0; }

.tabs_wr { padding: 0; }

.tabs_wr .ui-tabs-nav { display: flex; flex-wrap: wrap; margin: 0 0 30px -20px; }

.tabs_wr .ui-tabs-nav li { margin: 0 0 20px 20px; }

.tabs_wr .ui-tabs-nav li.ui-tabs-active { margin-bottom: 20px; }

.tabs_wr .ui-tabs-nav .ui-tabs-anchor { display: block; padding: 13px 15px; border: 1px solid #e53027; font-weight: 600; line-height: 1.25; color: #e53027; text-align: center; }

.tabs_wr .ui-tabs-nav li.ui-tabs-active .ui-tabs-anchor { background: #e53027; color: #fff; }

.tabs_wr.v1 .ui-tabs-nav li { flex-basis: 180px; flex-grow: 1; }

.tabs_wr.v1 .ui-tabs-nav li:first-child { flex-basis: 203px; flex-grow: 6; }

.slider .item { position: relative; }

.slider img { width: 100%; height: 100%; object-fit: cover; position: absolute; top: 0; left: 0; }

.slider .in { display: flex; justify-content: flex-end; align-items: flex-end; height: 575px; }

.slider .descr { background: #e53027; width: 100%; max-width: 590px; min-height: 224px; margin-right: -122px; padding: 22px 112px 30px 50px; color: #fff; }

.slider .title6 { max-height: 82px; margin-bottom: 0.5em; overflow: hidden; }

.slider .descr p { max-height: 72px; overflow: hidden; }

.slider .descr > :last-child { margin-bottom: 0; }

.slider_nav { width: 100%; max-width: 1440px; margin: 0 auto; position: relative; }

.slider_nav .slick-arrow { margin: 0; top: auto; bottom: 224px; }

.slider_nav .slick-prev { left: auto; right: 50px; }

.c_wr { padding-top: 27px; }

.b_info { display: grid; grid-template-columns: repeat(5, 203px); grid-template-rows: 48px auto 230px 48px auto 203px; grid-gap: 40px; padding: 12px 0 20px 0; position: relative; z-index: 1; }

.b_info::before { content: ""; background: #e5e5e5; width: 486px; height: 100%; position: absolute; top: 0; left: -20px; z-index: -1; }

.b_info a { color: inherit; text-decoration: none; }

.b_info a:hover { color: #e53027; }

.b_info .num { display: block; margin-bottom: 30px; font-size: 48px; color: #868686; }

.b_info .title7 { margin-bottom: 0; }

.b_info p { margin: 0; }

.b_info .thumb a { display: block; width: 203px; height: 203px; }

.b_info .thumb img { width: 100%; height: 100%; object-fit: cover; }

.b_info > :nth-child(1) { grid-column: 1/3; display: grid; grid-template-columns: min-content 1fr; align-items: baseline; text-align: center; }

.b_info > :nth-child(1) .num { margin: 0; }

.b_info :nth-child(2), .b_info :nth-child(4) { grid-row: 2; }

.b_info :nth-child(3), .b_info :nth-child(5), .b_info :nth-child(11), .b_info :nth-child(13) { grid-row: 3; }

.b_info :nth-child(6), .b_info :nth-child(8) { grid-row: 5; }

.b_info :nth-child(7), .b_info :nth-child(9), .b_info :nth-child(17) { grid-row: 6; }

.b_info :nth-child(10), .b_info :nth-child(12), .b_info :nth-child(14) { grid-row: 1/3; }

.b_info :nth-child(13)::before { content: ""; background: #e5e5e5; width: 2px; height: 100%; position: absolute; top: 0; margin-left: -21px; }

.b_info :nth-child(15)::before { content: ""; background: #e5e5e5; width: 2px; height: 414px; position: absolute; top: 0; margin-left: -21px; }

.b_info :nth-child(16) { grid-row: 4/6; }

.b_info :nth-child(18) { grid-column: 4/6; grid-row: 4/6; }

.b_info :nth-child(19) { grid-column: 4/6; grid-row: 6; }

.b_info :nth-child(19) a { width: auto; }

.b_info :nth-child(3)::after, .b_info :nth-child(5)::after, .b_info :nth-child(11)::after, .b_info :nth-child(13)::after, .b_info :nth-child(15)::after { content: ""; background: #000; display: block; height: 7px; margin-top: 20px; }

.b_info :nth-child(13)::after { margin-right: -40px; }

.c_wr1 { padding: 45px 0; }

.b_info1 { margin: 0 -10px; }

.b_info1 .thumb { display: flex !important; height: 100px; padding: 0 10px; filter: grayscale(1); transition: all 0.3s ease; }

.b_info1 .thumb:hover { filter: grayscale(0); }

.b_info1 img { max-height: 100%; margin: auto; }

.carousel { padding-bottom: 70px; }

.carousel .slick-arrow { margin: 0; top: auto; bottom: 0; }

.carousel .slick-prev { left: 10px; }

.carousel .slick-next { right: 10px; }

.c_wr2 { max-width: 1440px; margin-right: auto; margin-left: auto; padding: 117px 0 164px 0; }

.c_wr2 .in { display: flex; flex-wrap: wrap; align-items: center; }

.c_wr2 .head { flex-basis: 510px; flex-grow: 1; }

.c_wr2 .title6 { margin-bottom: 0.25em; }

.c_wr2 p { margin: 0; }

.c_wr2 .btn { flex-basis: 250px; flex-grow: 1; max-width: 466px; }

.c_wr2 .head, .c_wr2 .btn { margin: 0.5em 0; }

.c_wr2.v1 { background: #e5e5e5; margin-top: 150px; margin-bottom: 150px; padding: 54px 0; }

.c_wr3 { background: linear-gradient(to right, #e5e5e5 220px, #ffffff 220px); max-width: 1440px; margin-top: 117px; margin-right: auto; margin-left: auto; position: relative; }

.c_wr3.v1 { display: none; }

.b_form_wr { display: grid; grid-template-columns: minmax(auto, 470px) minmax(430px, auto); grid-auto-rows: max-content auto; max-width: 950px; margin-bottom: 180px; margin-left: auto; }

.bf_head { background: #e53027; display: flex; align-items: center; min-height: 210px; margin: 0; padding: 30px 70px; color: #fff; }

.bf_head .title6 { max-width: 220px; margin: 0; }

.bf_descr { background: #c0c0c0; display: flex; align-items: center; grid-row: 2; padding: 30px 70px; color: #302f2f; }

.bf_descr > :last-child { margin-bottom: 0; }

.b_form { background: #e5e5e5; grid-row: 1/3; padding: 70px 50px 70px 50px; }

.b_form form { max-width: 380px; margin: 0 auto; }

.b_form .f_row { margin-bottom: 1.1em; border-bottom: 1px solid #868686; }

.f_label { display: block; margin-bottom: 1.2em; font-size: 14px; color: #868686; }

.f_label span { color: #e53027; }

.b_form .input { background: none; padding: 0; border: none; }

.b_form textarea { height: auto; }

.b_form .switch_wr { display: flex; justify-content: space-between; }

.b_form .b_btn { padding-top: 2.2em; text-align: center; }

.b_form .btn { width: 100%; }

.c_wr4 { margin-bottom: 80px; }

.c_wr4 .in { height: 43vw; min-height: 140px; max-height: 490px; overflow: hidden; }

.c_wr4 img { width: 100%; height: 100%; object-fit: cover; }

.c_wr4 iframe { width: 100%; height: 100%; filter: grayscale(1); }

.c_wr5 { margin-bottom: 157px; }

.b_info2 { display: grid; grid-template-columns: minmax(280px, 365px) minmax(600px, auto); }

.bi2_descr { padding-left: 120px; }

.b_info2.v1 .bi2_head { background: #e5e5e5; display: grid; grid-template-columns: minmax(280px, 365px) minmax(600px, auto); grid-column: 1/3; position: relative; }

.b_info2.v1 .bi2_head::before { content: ""; background: #e5e5e5; width: 377px; height: 100%; position: absolute; top: 0; left: -377px; }

.b_info2.v1 .bi2_head::after { content: ""; background: url(../img/bkg1.jpg) 0 0/cover no-repeat; width: 377px; height: 100%; position: absolute; top: 0; right: -377px; }

.b_info2.v1 .bi2_head .title6 { display: flex; align-items: center; margin-bottom: 0; padding: 46px 0; }

.b_info2.v1 .bi2_descr { background: #e5e5e5; grid-column: 2; padding: 180px 120px; }

.b_info2.v1 .bi2_descr .title2 { color: #e53027; }

.b_info2.v1 .bi2_descr p { margin-bottom: 3.1em; }

.b_info2.v1 .bi2_descr > :last-child { margin-bottom: 0; }

.b_info2.v2 .bi2_head .title6 { color: #e53027; }

.b_info2.v2 .b_info3 { padding: 0; }

.b_info3 { display: flex; flex-wrap: wrap; margin: 0 -10px; padding: 55px 0 0 0; }

.b_info3 .item { width: calc(100% / 3 - 20px); margin: 0 10px 42px 10px; }

.b_info3 img { display: block; height: 86px; margin-bottom: 0.9em; }

.b_info3 p { max-width: 225px; }

.b_info3.v1 .item { width: calc(50% - 20px); }

.b_info3.v2 { padding-top: 0; }

.b_info4 { background: #e53027; display: flex; justify-content: space-between; padding: 36px 0 46px 120px; color: #fff; }

.b_info4 .item { width: calc(100% / 3); padding-right: 10px; }

.b_info4 .item:last-child { padding-right: 0; }

.b_info4 .title1 { display: block; }

.b_info4 .title1 span { font-size: 18px; }

.b_info4.v1 { background: #6a6a6a; }

.b_info2.v1 .bi2_head.v1 .b_info4 .item { max-width: 240px; }

.b_info2.v1 .bi2_head.v1 .b_info4.v1 { grid-column: 2; position: relative; }

.b_info2.v1 .bi2_head.v1 .b_info4.v1::before { content: ""; background: url(../img/left.jpg) 0 0/cover no-repeat; width: 741px; height: 100%; position: absolute; top: 0; right: 100%; }

.b_info2.v1 .bi2_head.v1::after { background-image: url(../img/right.jpg); }

.b_info2.v1 .bi2_head.v2::after { background: #e53027; }

.c_wr6 { padding-bottom: 157px; }

.c_wr7 { max-width: 1440px; margin: 0 auto; position: relative; }

.b_info5 .item { vertical-align: top; position: relative; }

.b_info5 .item::after { content: ""; background: rgba(0, 0, 0, 0.65); width: 100%; height: 100%; position: absolute; top: 0; left: 0; }

.b_info5 img { width: 100%; height: 100%; object-fit: cover; position: absolute; top: 0; left: 0; }

.b_info5 .in { display: flex; flex-direction: column; justify-content: space-between; height: 810px; padding-top: 80px; padding-bottom: 80px; z-index: 1; }

.b_info5 .title6 { color: #c0c0c0; }

.b_info5 .descr { max-width: 940px; font: 36px/1.35 'Play', sans-serif; font-display: swap; color: #fff; }

.b_info5 .descr > :last-child { margin-bottom: 0; }

.bi6_wr { width: 50px; height: 100%; padding: 50px 0; position: absolute; top: 0; right: 96px; }

.b_info6 { width: 110px; height: 100%; margin-left: -79px; font-size: 24px; line-height: 1; color: #fff; }

.b_info6::before { content: ""; height: 100%; border-right: 2px solid #c0c0c0; position: absolute; top: 0; right: 5px; }

.b_info6 .item { width: 110px; margin: 28px 0; position: relative; cursor: pointer; }

.b_info6 .item::after { content: ""; background: #c0c0c0; width: 12px; height: 12px; position: absolute; top: 50%; right: 0; transform: translateY(-50%); }

.c_wr7_arrows .slick-arrow { margin-top: 0; top: auto; }

.c_wr7_arrows .slick-prev { top: 0; }

.c_wr7_arrows .slick-prev::before { content: "\e85f"; }

.c_wr7_arrows .slick-next { bottom: 0; }

.c_wr7_arrows .slick-next::before { content: "\e860"; }

.carousel1 .slick-slide, .carousel2 .slick-slide { border: none; }

.carousel1 { background: #302f2f; }

.carousel2 { display: flex; align-items: center; }

.carousel2 .slick-list { height: 100%; }

.carousel2 .slick-current { font-weight: 700; color: #e53027; }

.carousel2 .slick-current .item::after { background: #e53027; }

.c_wr8 { padding: 170px 0 45px 0; }

.b_info7 { margin: 0 -20px; }

.b_info7 .thumb { height: 25vw; min-height: 190px; max-height: 272px; padding: 0 20px; filter: grayscale(1); }

.b_info7 img { width: 100%; height: 100%; object-fit: contain; }

.carousel3 { padding-top: 22px; padding-bottom: 65px; }

.carousel3 .slick-track { padding: 24px 0; }

.carousel3 .slick-center .thumb { transform: scale(1.18); filter: grayscale(0); }

.carousel3 .slick-arrow { margin: 0; top: auto; bottom: 0; }

.carousel3 .slick-prev { left: 20px; }

.carousel3 .slick-next { right: 20px; }

.c_wr9 { padding-bottom: 45px; }

.b_info8 .item { display: flex; align-items: flex-start; margin-bottom: 90px; }

.b_info8 .thumb_wr { display: grid; grid-template-columns: repeat(4, minmax(150px, 162px)); grid-template-rows: 142px; grid-gap: 20px; flex-shrink: 0; margin-right: 20px; }

.b_info8 .thumb { transition: all 0.3s ease; }

.b_info8 img { width: 100%; height: 100%; object-fit: cover; /* filter: grayscale(1); */ }

.b_info8 .thumb:hover img { filter: none; }

.b_info8 .thumb:nth-child(1) { grid-column: 1/3; grid-row: 1/3; }

.b_info8 .descr { flex-grow: 1; }

.b_info8 .title7 { min-height: 105px; margin-top: -0.2em; }

.b_info8 .title7 a { color: inherit; text-decoration: none; }

.bi8_btn { display: grid; grid-template-columns: repeat(4, 162px) 1fr; grid-gap: 0 20px; }

.bi8_btn .btn { grid-column: 3/5; }

.bi8_data { display: grid; grid-template-columns: max-content auto; grid-gap: 22px 20px; }

.bi8_data dt { font-family: 'Play', sans-serif; font-weight: 700; color: #e53027; }

.c_wr10 { padding-bottom: 105px; }

.b_info9 { max-width: 950px; padding-left: 57px; color: #505050; }

.b_info9 .item { padding-bottom: 1.55em; }

.b_info9 .head { padding-left: 1.82em; position: relative; color: #e53027; cursor: pointer; }

.b_info9 .head::before { content: "\e868"; font-family: "fontello"; padding: 0.3em; position: absolute; top: -0.3em; left: -0.3em; }

.b_info9 .head.open::before { content: "\e867"; }

.b_info9 .head:hover::before { background: #e5e5e5; }

.b_info9 .descr { display: none; padding-top: 1em; padding-bottom: 1.8em; }

.b_info9 .item:last-child .descr { padding-bottom: 0; }

.b_info9 .list1 { column-count: 2; column-width: 280px; column-gap: 140px; }

.b_info9 .list1 li { break-inside: avoid; }

.c_wr11 { padding-bottom: 40px; }

.carousel4 .slick-slide > div { height: 50vw; min-height: 190px; max-height: 670px; }

.carousel4 img { width: 100%; height: 100%; object-fit: cover; }

.carousel5 { margin-top: 20px; overflow: hidden; }

.carousel5 .slick-list { margin: 0 -10px; }

.carousel5 .slick-slide > div { padding: 0 10px; }

.carousel5 img { width: 100%; height: 100%; object-fit: cover; cursor: pointer; }

.hint { margin: 0; color: #e53027; }

.b_filter_wr { margin-bottom: 90px; }

.b_filter { display: grid; grid-template-columns: repeat(2, minmax(280px, 355px)); grid-gap: 20px; }

.b_filter_wr .hint { margin-top: 2.5em; }

.date { font-size: 14px; color: #868686; }

.b_info10 .item { display: flex; align-items: flex-start; margin-bottom: 20px; color: #868686; }

.b_info10 .thumb { flex-basis: 203px; flex-grow: 2; height: 263px; margin-right: 20px; }

.b_info10 img { width: 100%; height: 100%; object-fit: cover; }

.b_info10 .descr { flex-basis: 580px; flex-grow: 1; }

.b_info10 .date { margin: -0.35em 0 0.9em 0; }

.b_info10 .title7 { max-height: 54px; margin-bottom: 1.25em; overflow: hidden; color: #000; }

.b_info10 .title7 a { color: inherit; text-decoration: none; }

.b_info10 .title7 a:hover { color: #e53027; }

.b_info10 p { max-height: 96px; margin-bottom: 1.8em; overflow: hidden; }

.bi10_btn { display: flex; padding: 70px 0; }

.bi10_btn::after { content: ""; flex-basis: 600px; flex-grow: 1; }

.bi10_btn .btn { flex-basis: 203px; flex-grow: 2; }

.c_wr14 .search_form { max-width: 830px; margin-bottom: 90px; }

.b_info11 { max-width: 830px; }

.b_info11 .item { display: flex; align-items: flex-start; margin-bottom: 20px; color: #868686; }

.b_info11 .thumb { flex-shrink: 0; width: 174px; height: 174px; margin-right: 20px; }

.b_info11 img { width: 100%; height: 100%; object-fit: cover; }

.b_info11 .descr { flex-grow: 1; }

.b_info11 .date { margin: -0.35em 0 0.9em 0; }

.b_info11 .title7 { margin-bottom: 1.25em; color: #000; }

.b_info11 .title7 a { color: inherit; text-decoration: none; }

.b_info11 .title7 a:hover { color: #e53027; }

.b_info11 p { margin: 0; }

.bi11_btn { padding: 70px 0; }

.bi11_btn .btn { width: 100%; max-width: 466px; }

.c_wr15 { padding-bottom: 2.5em; text-align: center; }

.c_wr15 img { display: block; max-width: 90%; margin: 0 auto 1.15em auto; }

.c_wr15 .title1 { margin-bottom: 0.8em; }

.c_wr15 .btn { width: 100%; max-width: 466px; }

.b_info12 { background: #e53027; display: grid; grid-template-columns: repeat(2, 1fr); grid-gap: 15px 20px; padding: 46px 0 30px 120px; color: #fff; }

.b_info12 :nth-child(3) { grid-column: 2; grid-row: 1; }

.b_info12 .title4 { margin-bottom: 0; }

.b_info12 .list1 { margin-bottom: 0; padding-left: 1.5em; }

.c_wr16 { padding: 18px 0 150px 0; }

.c_wr16 .in { display: flex; justify-content: flex-end; }

.b_info13 { display: flex; flex-wrap: wrap; max-width: 1095px; margin: 0 -10px; }

.b_info13 .item { display: flex; flex-direction: column; width: calc(100% / 3 - 20px); margin: 0 10px 20px 10px; }

.b_info13 .title7 { margin-bottom: 0.57em; color: #e53027; }

.b_info13 .thumb1 { height: 18vw; max-height: 194px; margin-bottom: 20px; }

.b_info13 .thumb1 img { width: 100%; height: 100%; object-fit: cover; }

.b_info13 .descr { flex-grow: 1; padding-top: 65px; padding-bottom: 1em; }

.b_info13 .title4 { margin-bottom: 2.2em; }

.b_info13 .list1 { padding-left: 1.5em; }

.b_info13 .list1 li::before { color: #c0c0c0; }

.b_info13 .btn { width: 100%; max-width: 466px; }

.table1 { width: 100%; }

.table1 caption { background: #e53027; padding: 1em 20px; color: #fff; text-align: center; }

.table1 tr:nth-child(even) { background: #e5e5e5; }

.table1 th, .table1 td { padding: 1em 0 1em 20px; }

.table1 tr > :last-child { padding-right: 20px; text-align: right; }

.table1 th { font-weight: 700; }

.c_wr17 .in { display: grid; grid-template-columns: 466px 1fr; grid-gap: 0 20px; }

.c_wr17 .title2 { color: #e53027; }

.c_wr17 .tabs_wr { grid-column: 1/3; grid-row: 2; grid-gap: 0 20px; display: grid; grid-template-columns: 466px 1fr; padding-top: 24px; }

.c_wr17 .tabs_wr .ui-tabs-nav { margin-bottom: 45px; }

.c_wr17 .tabs_wr .ui-tabs-nav li { flex-basis: 180px; flex-grow: 1; }

.c_wr17 .tab_content { grid-column: 1/3; grid-row: 2; }

.c_wr17 .ui-tabs-panel > :last-child { margin-bottom: 0; }

.b_info14 { display: grid; grid-template-columns: 466px 1fr; grid-gap: 0 20px; margin-bottom: 100px; }

.b_info14 .table1 { max-width: 345px; }

.b_info14 .thumb { margin-bottom: 72px; }

.b_info14 .thumb.v1 { margin-top: -1.5em; }

.b_info14 .thumb.v2 { margin-top: -1em; }

.b_info14 .thumb.v3 { margin-top: -4.2em; }

.b_info14 .thumb_wr { display: flex; flex-wrap: wrap; margin: 0 -10px; padding-top: 8px; }

.b_info14 .thumb_wr .thumb { width: calc(100% / 3 - 20px); text-align: center; margin: 0 10px 2em 10px; }

.b_info14 .thumb_wr img { display: block; margin: 0 auto 0.8em auto; }

.b_info15 { display: flex; flex-wrap: wrap; margin: 0 -10px; }

.b_info15 .item { flex-basis: 100px; flex-grow: 1; max-width: 102px; margin: 0 10px 68px 10px; text-align: center; text-transform: uppercase; }

.b_info15 .thumb { width: 70px; height: 70px; margin: 0 auto 0.65em auto; border-radius: 50%; }

.b_info15 p { margin: 0; }

.c_wr18 { padding-bottom: 155px; }

.article_wr { padding-left: 243px; }

.article_wr h1, .article_wr h2, .article_wr h3 { color: #e53027; }

.article_wr h2, .article_wr h3 { margin-top: 3.3em; }

.article_wr p img { margin: 3.3em -0px; }

.article_wr .head { position: relative; }

.article_wr .date { width: 243px; position: absolute; top: 25px; left: -243px; font-size: 18px; }

.article { max-width: 710px; }

.article iframe { width: 100%; max-width: 710px; height: 50vw; min-height: 190px; max-height: 400px; margin: 3.3em 0; }

.article .list1 { margin: 3.3em 0; padding-left: 6.65em; color: #505050; }

.article .list1 li::before { color: #e53027; }

.article .b_info4 { margin: 4.8em 0; padding-left: 0; position: relative; }

.article .b_info4::before, .article .b_info4::after { content: ""; background: #e53027; width: 620px; height: 100%; position: absolute; top: 0; }

.article .b_info4::before { left: -620px; }

.article .b_info4::after { right: -620px; }

.article .b_info4 .item { max-width: 240px; }

.b_info16 { display: flex; flex-wrap: wrap; justify-content: space-between; max-width: 710px; margin: 1.65em 0; color: #e53027; }

.b_info16 p { display: flex; align-items: flex-end; margin: 1.65em 0; }

.b_info16 p:nth-child(odd) { flex-basis: 50%; padding-right: 20px; }

.b_info16 p:nth-child(even) { flex-basis: 50%; }

.b_info16 b { width: 2.1em; margin-right: 20px; font-size: 48px; line-height: 1; }

.b_info17 { display: flex; justify-content: space-between; }

.b_info17 .item { display: flex; flex-basis: 350px; flex-grow: 1; max-width: 466px; margin-bottom: 1.5em; }

.b_info17 .thumb { flex-shrink: 0; width: 100px; height: 100px; margin-right: 20px; }

.b_info17 img { width: 100%; height: 100%; object-fit: cover; filter: grayscale(1); transition: all 0.3s ease; }

.b_info17 .descr { flex-grow: 1; }

/*.b_info11 .title7 a:hover {color: #e53027;}*/
.b_info17 .date { font-size: 18px; margin: -0.3em 0 0.9em 0; }

.b_info17 .title8 { max-height: 61px; overflow: hidden; }

.b_info17 .title8 a { text-decoration: none; }

.b_info17 .item:hover img { filter: none; }

.b_info17 .item:hover .title8 a { text-decoration: underline; }

.b_info17 .item:nth-child(2) { flex-direction: row-reverse; text-align: right; }

.b_info17 .item:nth-child(2) .thumb { margin: 0 0 0 20px; }

.b_info18 { display: grid; grid-template-columns: 1fr 16.5em; max-width: 590px; margin: 45px 0 45px auto; }

.b_info18 > div { display: flex; flex-wrap: wrap; align-items: center; min-height: 60px; padding: 15px 20px; }

.bi18_address { background: #e53027; color: #fff; }

.bi18_social { background: #383838; display: flex; justify-content: center; font-size: 28px; line-height: 1; color: #fff; }

.bi18_social a { margin-right: 20px; color: inherit; }

.bi18_social a:last-child { margin-right: 0; }

.bi18_social a:hover { color: #e53027; }

.bi18_phone { background: #c0c0c0; justify-content: space-between; }

.bi18_email { background: #e5e5e5; justify-content: center; }

.c_wr21 { padding: 90px 0 65px 0; }

.c_wr21 .b_info2 { grid-template-rows: repeat(2, max-content) repeat(2, auto); }

.c_wr21 .bi2_descr { grid-row: 1/4; }

.b_filter1 { grid-row: 2; margin-bottom: 90px; padding-top: 25px; }

.b_filter1 select, .b_filter1 .jq-selectbox { max-width: 365px; }

.b_map { height: 700px; margin-top: 5px; position: -webkit-sticky; position: sticky; top: 0; }

#chartdiv { height: 100%; }

.bi19_wr { margin: -90px -100px 0 0; }

.bi19_wr .hint, .bi19_wr p { margin: 2.5em 0 0 0; }

.bi19_wr .btn { width: 100%; }

.b_info19 { margin: 80px 0 3em 0; }

.b_info19 .item { display: flex; align-items: flex-end; margin-bottom: 20px; }

.b_info19 .thumb { flex-shrink: 0; width: 100px; height: 100px; margin-right: 20px; }

.b_info19 img { width: 100%; height: 100%; object-fit: cover; filter: grayscale(1); transition: all 0.3s ease; }

.b_info19 .descr { flex-grow: 1; }

.b_info19 .title7 { margin: 0; color: #000; }

.b_info19 .title7 a { color: inherit; text-decoration: none; }

.b_info19 .item:hover img { filter: none; }

.b_info19 .item:hover .title7 a { color: #e53027; }

.fancybox-slide--html { padding: 20px; }

.fancybox-bg { background: #000; opacity: 0.8; }

.fancybox-button { background: #e53027; width: 60px; height: 60px; font-size: 20px; color: #fff; }

.fancybox-slide--html .fancybox-close-small { color: #fff; }

.fancybox-close-small { width: 50px; height: 50px; opacity: 1; }

.fancybox_gallery_a .fancybox-slide--image { padding: 20px; }

.fancybox_gallery_a .fancybox-close-small { top: 0; right: 0; }

.fancybox_gallery_a .fancybox-navigation .fancybox-button--arrow_right, .fancybox_gallery_a .fancybox-navigation .fancybox-button--arrow_left { background: #c0c0c0; width: 50px; height: 50px; padding: 0; font-size: 20px; color: #302f2f; }

.popup { background: #e5e5e5; display: none; width: 100%; max-width: 950px; padding: 50px 40px; text-align: center; }

.popup .in { display: flex; flex-direction: column; justify-content: center; min-height: 460px; }

.popup .title6 { max-width: 420px; margin: 0 auto 0.8em auto; color: #e53027; }

.popup p { font-size: 1.33em; }

.popup .b_btn { padding-top: 2em; }

.popup .btn { width: 100%; max-width: 346px; }

.popup_c { background: #fff; display: none; width: 100%; max-width: 1196px; padding: 0; }

.popup_c .descr { background: #e5e5e5; display: grid; grid-template-columns: 466px 1fr; }

.popup_c .descr .title7 { background: #e53027; display: flex; align-items: center; margin: 0; padding: 22px 50px; color: #fff; }

.popup_c .bi8_data { grid-template-columns: max-content auto max-content auto; padding: 22px 20px; }

#popup_d { background: none; display: none; width: 100%; max-width: 950px; padding: 0; }

#popup_d .b_form_wr { margin: 0; }

/* ------------------------------- Footer  ------------------------------- */
#footer { background: linear-gradient(to right, #e53027 50%, #302f2f 50%); position: relative; color: #fff; }

#footer > .in { display: flex; padding-right: 0; }

#footer .col:nth-child(1) { flex-basis: 173px; flex-shrink: 0; padding: 68px 20px 35px 122px; padding-left: 0; }

#footer .col:nth-child(2) { background: #302f2f; flex-grow: 1; display: grid; grid-template-columns: 1fr 220px; grid-template-rows: min-content auto min-content; grid-gap: 0 20px; align-items: start; padding: 68px 20px 35px 70px; }

#footer .col:nth-child(3) { flex-basis: 480px; flex-shrink: 0; margin-right: -102px; }

#footer .col:nth-child(2) .title4 { color: #c0c0c0; }

.f_menu { margin: 0; padding: 0; list-style: none; }

.f_menu li { margin-bottom: 0.8em; }

.f_menu a { color: inherit; text-decoration: none; }

#footer .col:nth-child(1) .f_menu a:hover { color: #302f2f; }

#footer .col:nth-child(2) .f_menu a:hover { color: #e53027; }

#footer .col:nth-child(2) .f_menu { grid-column: 1/3; display: grid; grid-template-columns: 220px 1fr; grid-gap: 0 20px; }

.copy { font-size: small; color: #c0c0c0; }

.copy p { margin: 0; }

.copy a { color: inherit; }

.f_social { display: flex; font-size: 28px; line-height: 1; }

.f_social a { margin-right: 20px; color: inherit; }

.f_social a:hover { color: #e53027; }

.f_contacts { display: grid; grid-template-columns: 63% 37%; grid-template-rows: minmax(323px, auto) min-content; height: 100%; }

.f_map { grid-column: 1/3; position: relative; overflow: hidden; }

.f_map iframe { width: 100%; height: 100%; position: absolute; top: 0; left: 0; }

.f_address { background: #505050; padding: 15px 20px; }

.f_phone { background: #e53027; padding: 15px 20px; text-align: center; }

@media screen and (max-width: 1234px) { .slider .descr { max-width: 500px; margin-right: -20px; padding-right: 20px; }
  .b_info { grid-template-columns: repeat(4, 203px); grid-template-rows: auto; max-width: 932px; margin: 0 auto; }
  .b_info::before { width: calc(100% + 40px); height: 446px; }
  .b_info > :nth-child(1) { grid-column: 1/5; }
  .b_info :nth-child(2), .b_info :nth-child(4) { grid-row: auto; }
  .b_info :nth-child(3), .b_info :nth-child(5), .b_info :nth-child(7), .b_info :nth-child(9) { grid-row: 3; }
  .b_info :nth-child(6), .b_info :nth-child(8) { grid-row: auto; }
  .b_info :nth-child(11), .b_info :nth-child(13), .b_info :nth-child(15) { grid-row: 5; }
  .b_info :nth-child(10), .b_info :nth-child(12), .b_info :nth-child(14) { grid-row: auto; }
  .b_info :nth-child(16), .b_info :nth-child(17) { grid-row: auto; }
  .b_info :nth-child(18) { grid-column: 1/3; grid-row: auto; }
  .b_info :nth-child(19) { grid-column: 3/5; grid-row: auto; }
  .b_info :nth-child(13)::before, .b_info :nth-child(15)::before { display: none; }
  .b_info :nth-child(7)::after, .b_info :nth-child(9)::after, .b_info :nth-child(17)::after { content: ""; background: #000; display: block; height: 7px; margin-top: 20px; }
  .b_info :nth-child(11)::after { margin-right: -40px; }
  .b_info :nth-child(13)::after { margin-right: 0; }
  .b_info :nth-child(15)::after { margin-right: -40px; }
  .bi2_descr { padding-left: 50px; }
  .b_info2.v1 .bi2_descr { padding: 50px; }
  .b_info4, .b_info12 { padding-left: 50px; }
  .b_info5 .descr { max-width: 1050px; padding-right: 150px; }
  .bi6_wr { right: 20px; }
  #footer .col:nth-child(2) { padding-left: 20px; }
  #footer .col:nth-child(3) { margin-right: 0; } }

@media screen and (max-width: 1124px) { #header { width: 100%; padding-bottom: 0; position: fixed; top: 0; left: 0; z-index: 200; }
  .h_lang { display: none; }
  .h_menu { display: none; }
  #header.v1 .logo { margin-top: 0; }
  .search_ctrl { margin-left: 0; }
  .m_ctrl { display: block; }
  #main { padding-top: 205px; }
  .c_wr { margin-top: -205px; padding-top: 0; }
  .c_wr .in { z-index: 1; }
  .b_info { padding-top: 80px; position: static; }
  .b_info::before { height: 513px; }
  .popup_c .descr { grid-template-columns: 406px 1fr; }
  .popup_c .descr .title7 { padding-right: 20px; padding-left: 20px; }
  #footer { background: none; }
  #footer > .in { display: grid; grid-template-columns: minmax(190px, 1fr) minmax(480px, 1fr); padding-left: 0; }
  #footer .col:nth-child(1) { background: #e53027; padding-left: 20px; }
  #footer .col:nth-child(2) { background: #c0c0c0; grid-column: 1/3; grid-template-columns: minmax(190px, 1fr) minmax(480px, 1fr); grid-gap: 0; align-items: stretch; padding: 0; }
  #footer .col:nth-child(2) .title4 { display: none; }
  #footer .col:nth-child(2) .f_menu { display: none; }
  #footer .col:nth-child(3) { grid-column: 2; grid-row: 1; }
  .copy, .f_social { display: flex; align-items: center; padding: 15px 20px; }
  .copy { justify-content: center; grid-column: 2; color: #302f2f; text-align: center; }
  .f_social { background: #302f2f; grid-row: 1; } }

@media screen and (max-width: 1080px) { .b_info7 .thumb { max-height: 320px; }
  .b_info2 { display: block; }
  .bi2_descr { padding: 0; }
  .b_info2.v1 .bi2_head { display: block; margin-left: -20px; padding-right: 70px; }
  .b_info2.v1 .bi2_head::before { display: none; }
  .b_info2.v1 .bi2_head::after { width: 90px; right: -20px; }
  .b_info2.v1 .bi2_head .title6 { padding: 20px; }
  .b_info2.v1 .bi2_head .title6 br { display: none; }
  .b_info2.v1 .bi2_descr { background: none; padding: 80px 0 100px 0; }
  .b_info2.v1 .bi2_head.v1 .b_info4.v1::before { display: none; }
  .b_info2.v1 .bi2_head.v2 { margin-right: -20px; padding-right: 0; }
  .b_info2.v1 .bi2_head.v2::after { display: none; }
  .b_info4 { padding: 13px 20px 20px 20px; }
  .b_info4 .item { max-width: 100px; }
  .b_info12 { padding: 20px; }
  .c_wr5 { margin-bottom: 100px; }
  .c_wr8 { padding-top: 100px; }
  .b_info8 .item { display: block; }
  .b_info8 .thumb_wr { margin: 0 0 20px 0; }
  .b_info8 .title7 { min-height: auto; }
  .bi8_btn { display: block; text-align: center; }
  .bi8_btn .btn { width: 100%; max-width: 344px; }
  .c_wr2.v1 { margin: 100px 0; }
  .c_wr17 .in { display: block; }
  .c_wr17 .tabs_wr { display: block; padding-top: 0; }
  .c_wr17 .tabs_wr .ui-tabs-nav { margin-bottom: 30px; }
  .b_info14 { display: block; margin-bottom: 50px; }
  .bi4_col { margin-bottom: 3em; }
  .b_info14 .thumb { margin-bottom: 3em; }
  .b_info14 .thumb.v1, .b_info14 .thumb.v2, .b_info14 .thumb.v3 { margin-top: 0; }
  .b_info14 .table1 { max-width: 466px; }
  .article_wr { padding-left: 0; }
  .article_wr .date { width: auto; margin-bottom: 1.1em; position: static; }
  .article { max-width: none; }
  .article .b_info4 { margin-right: -20px; margin-left: -20px; padding-right: 20px; padding-left: 20px; }
  .article .b_info4::before, .article .b_info4::after { display: none; }
  .c_wr18 { padding-bottom: 100px; }
  .b_info18 { margin-left: 0; }
  .c_wr21 { padding: 10px 0 0 0; }
  .bi19_wr { max-width: 465px; margin-top: 0; margin-right: 0; }
  .b_map { position: static; }
  .popup_c .descr { display: block; } }

@media screen and (max-width: 980px) { .b_info { grid-template-columns: repeat(2, 203px); max-width: 446px; }
  .b_info::before { height: 820px; }
  .b_info > :nth-child(1) { grid-column: 1/3; }
  .b_info :nth-child(3), .b_info :nth-child(5) { grid-row: 3; }
  .b_info :nth-child(7), .b_info :nth-child(9) { grid-row: 5; }
  .b_info :nth-child(11), .b_info :nth-child(13) { grid-row: 7; }
  .b_info :nth-child(15), .b_info :nth-child(17) { grid-row: 9; }
  .b_info :nth-child(18), .b_info :nth-child(19) { grid-column: 1/3; }
  .b_info :nth-child(7)::after, .b_info :nth-child(9)::after { display: none; }
  .b_info :nth-child(11)::after, .b_info :nth-child(13)::after, .b_info :nth-child(15)::after { display: block; }
  .b_info :nth-child(11)::after, .b_info :nth-child(13)::after { margin-right: 0; }
  .b_info :nth-child(15)::after { margin-right: -40px; }
  .c_wr3 { background: none; }
  .bf_head, .bf_descr { padding-right: 50px; padding-left: 50px; }
  .b_info9 .list1 { column-gap: 50px; }
  .tabs_wr.v1 .ui-tabs-nav li:first-child { flex-basis: 180px; flex-grow: 1; }
  .b_info10 .item { display: block; margin-bottom: 3em; }
  .b_info10 .thumb { max-width: 466px; height: 54vw; max-height: 263px; margin: 0 0 20px 0; }
  .b_info10 .date { margin-top: 0; }
  .b_info10 .title7 { max-height: none; }
  .b_info10 p { margin-bottom: 1.1em; max-height: none; }
  .bi10_btn { display: block; padding: 0; text-align: center; }
  .bi10_btn::after { display: none; }
  .bi10_btn .btn { width: 100%; max-width: 466px; }
  .bi11_btn { padding: 0; text-align: center; }
  .b_info17 { display: block; }
  .b_info17 .item:nth-child(2) { flex-direction: row; text-align: left; }
  .b_info17 .item:nth-child(2) .thumb { margin: 0 20px 0 0; }
  .b_info17 .title8 { max-height: none; }
  .popup { padding-right: 10px; padding-left: 10px; }
  .popup .in { display: block; min-height: auto; } }

@media screen and (max-width: 850px) { .b_form_wr, .bf_head, .bf_descr { display: block; }
  .bf_head { min-height: auto; }
  .bf_head .title6 { max-width: none; }
  .b_form { padding-top: 50px; padding-bottom: 50px; }
  .b_info7 .thumb { height: 38vw; }
  .b_info13 .item { width: calc(50% - 20px); }
  .b_info13 .thumb1 { height: 26vw; max-height: 217px; max-height: none; }
  #popup_d .fancybox-button { background: #302f2f; } }

@media screen and (max-width: 730px) { .title1, .title5 { font-size: 35px; }
  .b_info3 .item { width: calc(50% - 20px); }
  .b_info3 .empty { display: none; }
  .b_info5 .in { height: 600px; padding-top: 50px; padding-bottom: 50px; }
  .b_info5 .descr { font-size: 24px; }
  .b_info6 { width: 90px; margin-left: -59px; font-size: 18px; }
  .b_info8 .thumb_wr { grid-template-columns: repeat(2, minmax(140px, auto)); grid-template-rows: auto; }
  .b_info12 { display: block; }
  .b_info12 .title4 { margin-bottom: 0.8em; }
  .b_info12 .list1 { margin-bottom: 1.8em; }
  .b_info12 > :last-child { margin-bottom: 0; }
  .article > img { width: auto !important; height: auto !important; float: none !important; margin: 0 0 1.1em 0 !important; }
  #footer > .in { grid-template-columns: 190px 1fr; }
  #footer .col:nth-child(1) { padding-top: 35px; padding-bottom: 100px; }
  #footer .col:nth-child(2) { grid-template-columns: repeat(2, 50%); }
  .f_contacts { grid-template-rows: minmax(250px, auto) min-content; margin-left: -190px; }
  .f_map { margin-left: 190px; } }

@media screen and (max-width: 640px) { .b_info7 .thumb { height: 48vw; }
  .b_filter { grid-template-columns: 100%; }
  .b_info11 .title7 { margin-bottom: 0; }
  .b_info11 p { display: none; }
  .b_info13 { max-width: 350px; margin-right: auto; margin-left: auto; }
  .b_info13 .item { width: 100%; margin: 0 0 3em 0; display: block; }
  .b_info13 .item:last-child { margin-bottom: 0; }
  .b_info13 .thumb1 { height: 52vw; max-height: 192px; }
  .b_info15 .item { flex-basis: calc(25% - 20px); flex-grow: 0; max-width: none; margin-bottom: 2em; }
  .b_info16 { display: block; }
  .b_info16 p:nth-child(odd) { padding-right: 0; }
  .popup_c .bi8_data { grid-template-columns: max-content auto; } }

@media screen and (max-width: 550px) { body { font-size: 14px; }
  .in { padding-right: 10px; padding-left: 10px; }
  .btn-prld { min-height: auto; }
  .title1, .title6 { font-size: 24px; }
  .title2, .title7 { font-size: 18px; }
  .title3, .title4 { font-size: 14px; }
  .hdr_row .in { height: 35px; }
  .logo { width: 70px; padding: 5px; background: #e5e5e5; }
  .search_ctrl { font-size: 20px; line-height: 35px; }
  .search_form input[type="text"] { padding-right: 60px; border-width: 1px; }
  .search_form button { width: 60px; height: calc(100% - 2px); top: 1px; right: 1px; font-size: 20px; }
  .m_ctrl { width: 35px; height: 35px; font-size: 21px; line-height: 35px; }
  .mob_menu_wr { width: calc(100% - 90px); max-height: calc(100vh - 35px); right: 10px; }
  #main { padding-top: 120px; }
  .slick-arrow { width: 35px; height: 35px; }
  .slick-prev::before, .slick-next::before { font-weight: bold; font-size: 14px; }
  .carousel3 { padding-top: 0; }
  .carousel3 .slick-prev { left: 10px; }
  .carousel3 .slick-next { right: 10px; }
  .slider .in { height: calc(100vh); height: calc(var(--vh, 1vh) * 100); /*mob_fix*/ }
  .slider .descr { max-width: 286px; min-height: 177px; margin-right: -10px; padding: 15px 20px 20px 20px; }
  .slider .title6 { max-height: 54px; }
  .slider .descr p { max-height: 76px; }
  .slider_nav .slick-arrow { bottom: 177px; }
  .slider_nav .slick-prev { right: 35px; }
  .c_wr { margin-top: -120px; }
  .b_info { grid-template-columns: repeat(2, minmax(140px, 1fr)); grid-gap: 20px; }
  .b_info::before { width: calc(100% + 20px); height: 182vw; max-height: 617px; left: -10px; }
  .b_info .num { margin-bottom: 10px; font-size: 36px; }
  .b_info .thumb a { width: auto; height: 44vw; min-height: 140px; max-height: 157px; }
  .b_info .title7 br { display: none; }
  .b_info :nth-child(10), .b_info :nth-child(12) { margin-top: 20px; }
  .b_info :nth-child(3)::after, .b_info :nth-child(5)::after, .b_info :nth-child(11)::after, .b_info :nth-child(13)::after, .b_info :nth-child(15)::after, .b_info :nth-child(17)::after { height: 5px; }
  .carousel { padding-bottom: 55px; }
  .c_wr2 { padding: 45px 0 90px 0; }
  .c_wr2.v1 { margin: 75px 0; padding-top: 12px; padding-bottom: 12px; }
  .c_wr3 { margin-top: 45px; }
  .b_form_wr { margin-bottom: 90px; }
  .bf_head, .bf_descr { padding: 15px 20px; }
  .b_form { padding: 35px 20px 20px 20px; }
  .c_wr4 { margin-bottom: 30px; }
  .c_wr5 { margin-bottom: 75px; }
  .b_info2.v1 .bi2_head { margin-left: -10px; padding-right: 50px; }
  .b_info2.v1 .bi2_head::after { width: 60px; right: -10px; }
  .b_info2.v1 .bi2_head .title6 { padding: 18px 10px; }
  .b_info2.v1 .bi2_descr { padding-bottom: 0; }
  .b_info2.v1 .bi2_head.v1 { margin-right: -10px; padding-right: 0; }
  .b_info2.v1 .bi2_head.v1::after { display: none; }
  .b_info2.v1 .bi2_head.v1 .title6 { padding-right: calc(46% + 10px); position: relative; }
  .b_info2.v1 .bi2_head.v1 .title6::after { content: ""; background: url(../img/left.jpg) center/cover no-repeat; width: 46%; height: 100%; position: absolute; top: 0; right: 0; }
  .b_info2.v1 .bi2_head.v2 { margin-right: -10px; }
  .b_info3 { padding-top: 20px; }
  .b_info3 .item { margin-bottom: 15px; }
  .b_info3 img { height: 60px; }
  .b_info4 { padding-right: 10px; padding-left: 10px; }
  .b_info4 .title1 span { font-size: 14px; }
  .c_wr6 { padding-bottom: 75px; }
  .c_wr8 { padding-top: 65px; }
  .b_info7 { margin: 0 -10px; }
  .b_info7 .thumb { height: 58vw; padding: 0 10px; }
  .b_info5 .in { height: 70vw; min-height: 210px; max-height: 350px; padding-top: 20px; padding-bottom: 20px; }
  .b_info5 .descr { padding-right: 95px; font-size: 14px; }
  .bi6_wr { width: 36px; padding: 36px 0; right: 10px; }
  .b_info6 { width: 65px; margin-left: -44px; font-size: 14px; }
  .b_info6::before { border-right-width: 1px; right: 3px; }
  .b_info6 .item { margin: 8px 0; }
  .b_info6 .item::after { width: 7px; height: 7px; }
  .c_wr7_arrows .slick-arrow { width: 36px; height: 36px; }
  .b_info8 .item { margin-bottom: 50px; }
  .bi8_data { grid-gap: 6px 20px; }
  .c_wr10 { padding-bottom: 45px; }
  .b_info9 { padding-left: 55px; }
  .carousel5 { margin-top: 10px; }
  .carousel5 .slick-list { margin: 0 -5px; }
  .carousel5 .slick-slide > div { padding: 0 5px; }
  .b_filter_wr, .c_wr14 .search_form { margin-bottom: 2.5em; }
  .b_info11 .thumb { width: 90px; height: 90px; }
  .c_wr16, .c_wr18 { padding-bottom: 75px; }
  .b_info13 .descr { padding-top: 35px; }
  .b_info13 .title4 { margin-bottom: 0.8em; }
  .b_info15 .thumb { width: 60px; height: 60px; }
  .breadcrumbs { margin-bottom: 1.1em; }
  .breadcrumbs li::after { font-size: 1em; margin: 0 0.5em; }
  .article_wr .date, .b_info17 .date { font-size: 14px; }
  .article .b_info4 { margin: 3.3em -10px; padding-right: 10px; padding-left: 10px; }
  .article .list1 { padding-left: 4.5em; }
  .b_info16 b { font-size: 36px; }
  .b_info17 .thumb { width: 90px; height: 90px; }
  .bi18_social { font-size: 20px; }
  .b_filter1 { margin-bottom: 3em; padding-top: 0; }
  .b_info19 { margin-top: 3em; }
  .b_info19 .thumb { width: 90px; height: 90px; }
  .popup { padding-top: 40px; padding-bottom: 20px; }
  .fancybox-button { width: 35px; height: 35px; padding: 5px; font-size: 20px; }
  .fancybox-slide--html { padding: 10px; }
  .fancybox-slide--html .fancybox-close-small { padding: 5px; }
  .fancybox_gallery_a .fancybox-slide--image { padding: 10px; }
  .fancybox_gallery_a .fancybox-navigation .fancybox-button { top: calc(50% - 35px); }
  .fancybox_gallery_a .fancybox-navigation .fancybox-button--arrow_right, .fancybox_gallery_a .fancybox-navigation .fancybox-button--arrow_left { width: 35px; height: 35px; font-size: 14px; }
  .popup_c .descr .title7, .popup_c .bi8_data { padding-top: 15px; padding-bottom: 15px; }
  #popup_d .bf_head { padding-right: 35px; }
  #footer .col:nth-child(1) { padding: 20px 10px 70px 10px; }
  .f_address, .f_phone, .copy, .f_social { padding: 10px; } }

@media screen and (max-width: 480px) { .tabs_wr .ui-tabs-nav { margin-left: -10px; }
  .tabs_wr .ui-tabs-nav li { margin: 0 0 10px 10px; }
  .tabs_wr .ui-tabs-nav li.ui-tabs-active { margin-bottom: 10px; }
  .tabs_wr.v1 .ui-tabs-nav li, .tabs_wr.v1 .ui-tabs-nav li:first-child { flex-basis: 145px; }
  .c_wr17 .tabs_wr .ui-tabs-nav li { flex-basis: 145px; }
  .b_info14 .thumb_wr .thumb { width: calc(50% - 20px); }
  .b_info18 { grid-template-columns: 1fr max-content; }
  .b_info18 > div { min-height: 50px; padding: 5px 10px; }
  #footer > .in { grid-template-columns: 110px 1fr; }
  #footer .col:nth-child(2) { grid-template-columns: minmax(180px, 50%) 1fr; }
  .f_menu li { margin-bottom: 0.3em; }
  .f_contacts { grid-template-columns: minmax(180px, 50%) 1fr; grid-template-rows: minmax(130px, auto) min-content; margin-left: -110px; }
  .f_map { margin-left: 110px; } }

@media screen and (max-width: 375px) { .b_info5 .title6 { font-size: 18px; }
  .b_info6 .item { margin: 6px 0; }
  .b_info11 .title7, .b_info17 .title8 { font-size: 14px; } }

@media screen and (max-width: 360px) { .title1, .title5 { font-size: 24px; }
  .b_info15 .item { flex-basis: calc(100% / 3 - 20px); } }

/*html {background: url(../img/abc.jpg) center 0 no-repeat;}
body {opacity: 0.5;}*/
.b_form .error-msg { color: #e53027; position: absolute; right: 70px; }

.b_form .valid-msg { color: #00C900; position: absolute; right: 70px; }

.b_form .error { border-bottom: 1px solid #e53027 !important; }

.b_form ::-webkit-input-placeholder { color: #868686; }

.b_form ::-ms-input-placeholder { color: #868686; }

.b_form ::placeholder { color: #868686; }

.hide { display: none; }

.design_questions_text ul { column-count: 2; column-width: 280px; column-gap: 140px; padding-left: 2.38em; list-style-type: none; }

.design_questions_text ul li { break-inside: avoid; margin-bottom: 0.55em; }

.design_questions_text ul li::before { content: "\25FC"; display: inline-block; width: 2.2em; margin-left: -2.2em; position: relative; top: -0.2em; font-size: 0.7em; }
